@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html body {
  background: #fbfbfb;
  font-family: 'Roboto', Arial, sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input,
button {
  outline: 0;
}

.link {
  text-decoration: none !important;
  cursor: pointer;
}

.table tr {
  cursor: pointer;
}

.login-container {
  /* top: 50px; */
  background: #E9E9E9;
  border: 1px solid #CFCFCF;
  box-sizing: border-box;
  box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.25);
}

.welcome {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #666666;
}

.form-login {
  background: #F8F8F8;
  border: 3px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 16px;
}

.login-input label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #717171;
}

.btn-login {
  /* font-weight: bold !important; */
  background-color: #a41406 !important;
  border-color: #a41406 !important;
  color: white !important;
}

.forgot-password {
  color: #cc0007;
}

.forgot-password:hover {
  text-decoration: none;
  color: #9f1317;
}

/* .login {
  max-width: 1170px;
  margin: 0 auto;
}

.content {
  background: #edecec;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 55px 30px 30px 30px;
}

.content #welcome {
  margin-top: 39px;
  font-size: 21px;
  color: #666666;
}

.content form {
  padding-top: 15px;
  width: 100%;
}

.content form label {
  font-size: 18px;
  color: #666666;
}

.content form input[type='text'],
input[type='password'] {
  margin: 7px 0 22px;
  width: 100%;
  height: 55px;
  padding: 21px 15px 10px;

  font-family: inherit;
  font-size: 21px;
  font-weight: 300;

  border: 1px solid #979797;
  border-radius: 5px;
}

.content form input[type='text']:focus,
input[type='password']:focus {
  background: #f7f2df;
  border: 2px solid #e8cb57;
  box-shadow: 0 4px 25px rgba(255, 187, 0, 0.15);
}

.content form button {
  margin: 7px 0 22px;
  width: 100%;
  background: #cc0007;
  color: #f0f0f0;
  padding: 15px 15px 15px;

  font-family: inherit;
  font-size: 21px;
  font-weight: 400;
  border: 2px solid #a50006;
  border-radius: 5px;

  cursor: pointer;
}

.content form #forgot-password {
  padding: 0;
  margin-top: -14px;
  text-align: center;
}

.content form #forgot-password a {
  text-decoration: none;
  color: #cc0007;
}

@media only screen and (min-width: 490px) {
  .login {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .content {
    max-width: 390px;
    height: 680px;
    border: 1px solid #b5b5b5;
    margin-top: 130px;
    box-shadow: 0 4px 25px rgba(121, 121, 121, 50);
  }
} */

.contacts span {
  font-size: 18px;
  cursor: pointer;
}

.contacts p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  color: #717171;
}

.contacts h5 {
  font-style: normal;
  font-weight: bold;
  line-height: 19px;
  margin: 0;
  color: #666;
}

.resume a {
  text-decoration: none !important;
}


.table tr {
  cursor: pointer;
}

.table tr {
  cursor: pointer;
}

